import { Injectable } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';  
import { map } from  'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  SERVER_URL: string = "https://spectromnodetest.azurewebsites.net/api/BlobStorageTrigger?"; 
  DOWNLOAD_URL: string = "https://spectromnodetest.azurewebsites.net/api/BlobRetrievePhotoView?"
  // DOWNLOAD_URL: string = "http://localhost:7071/api/BlobRetrievePhotoView?"

  constructor(private http: HttpClient, public oktaAuth: OktaAuthService) { }

  private async request(method: string, url: string, data?: any) {
    const token = await this.oktaAuth.getAccessToken();
    console.log("data");
    console.log(data);
    // console.log("token" + token);
    const result = this.http.request(method, url, {
      body: data,
      reportProgress: true,  
      responseType: 'json',
      observe: 'events',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        Authorization: `Bearer ${token}`
      }
    });

    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  private async getToken() {
    await this.oktaAuth.getAccessToken();
  }

  public upload(formData, filename) {
    const token = this.getToken();
    // const token = await this.oktaAuth.getAccessToken();
    // return this.request('POST', this.SERVER_URL, formData );
    return this.http.post<any>(`${this.SERVER_URL}filename=${filename}`, formData, {  
        reportProgress: true,  
        observe: 'events',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          Authorization: `Bearer ${token}`
        }  
    });  
  }

  public download(filename): Observable<Blob> {
    const token = this.getToken();
    return this.http.get(`${this.DOWNLOAD_URL}filename=${filename}`, {  
      responseType: 'blob',
  });  
  }

  // public download(filename) {
  //   const token = this.getToken();
  //   return this.http.get(`${this.DOWNLOAD_URL}filename=${filename}`, {  
  //     observe: 'response',
  //     // responseType: 'form-data',
  // });  
  // }
  // }
}
