<div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="dataSource.loading$ | async">
      <mat-spinner *ngIf="dataSource.loading$ | async"></mat-spinner>
      <!-- <div class="example-rate-limit-reached" *ngIf="isRateLimitReached"> -->
        <!-- Broken! -->
      <!-- </div> -->
</div>

<div class="example-table-container">

    <!-- <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="ARSxxx">
      </mat-form-field> -->

    <!-- <div class="col-md-2">
        <button type="button" class="btn btn-primary" (click)="pingDevice('CVC-BT-South','cvc.jpg')"><i class="fa fa-plus"></i> Ping</button>
    </div>
    <ul>  
        <li *ngFor="let file of files">  
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <mat-progress-bar [value]="file.progress"></mat-progress-bar>  
            <span id="file-label">  
                  
            </span>  
        </li>  
    </ul>   -->
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="id" matSortDisableClear matSortDirection="desc" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="button-row">
                        <div class="button-col">
                          <button type="button" class="btn btn-primary" (click)="onUploadClick(element.id)">  Upload <i class="fa fa-upload"></i></button>
                        </div>
                        <div class="button-col">
                          <button type="button" class="btn btn-secondary" (click)="onViewPhotoClick(element.id)">View <i class="fa fa-television    "></i></button>
                        </div>
                        <div class="button-col">
                        <button mat-fab color="primary"  (click)="onPingDeviceClick(element.id)">
                            <mat-icon>wifi</mat-icon>
                          </button>
                          </div>
                        <div><input type="file" #fileUpload id="fileUpload" name="fileUpload" accept="image/*" data-max-size="2048" style="display:none;" /></div>
                        <!-- <div><mat-spinner *ngIf="this.uploadInProgress" [value]="this.uploadProgressValue" [mode]="indeterminate"></mat-spinner></div> -->
                        <div><mat-spinner *ngIf="this.uploadInProgress" ></mat-spinner></div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element; loadDevice(element.id)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>

    <mat-paginator [length]="deviceCount" [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>


</div>

