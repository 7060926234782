import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DevicesComponent } from './devices/devices.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuthModule } from '@okta/okta-angular';
import { environment } from '../environments/environment';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import { ViewDialogComponent } from './viewer-dialog/viewer-dialog.component';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { PrivacystatementComponent } from './privacystatement/privacystatement.component';
import { AboutComponent } from './about/about.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    AppComponent,
    DevicesComponent,
    HomeComponent,
    ViewDialogComponent,
    TermsofserviceComponent,
    PrivacystatementComponent,
    AboutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OktaAuthModule.initAuth({
      issuer: 'https://dev-704127.okta.com/oauth2/default',
      // redirectUri: 'https://victorious-plant-01b8bee10.azurestaticapps.net/implicit/callback',
      redirectUri: 'https://www.cloudviewcontroller.com/implicit/callback',
      // redirectUri: 'http://localhost:4200/implicit/callback',
      clientId: '0oajajnfjtzE0TGXU4x6'
    }),
    MatPaginatorModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    HttpClientModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
