<nav class="navbar navbar-expand navbar-light bg-light">
  <a class="navbar-brand" [routerLink]="['']">
    <i class="fa fa-cloud"></i>
  </a>
  <ul class="navbar-nav mr-auto">
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['']">
        Home
      </a>
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link" [routerLink]="['timeline']">
        Timeline
      </a>
    </li> -->
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['devices']">
        Devices
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['about']">
        About
      </a>
    </li>
    <!-- <button class="nav-item" mat-button [matMenuTriggerFor]="menu">Parts</button> -->
  </ul>
  <span>
    <button class="btn btn-primary" *ngIf="!isAuthenticated" (click)="login()"> Login </button>
    <button class="btn btn-primary" *ngIf="isAuthenticated" (click)="logout()"> Logout </button>
  </span>
</nav>
<router-outlet></router-outlet>