<div>
    <h2 mat-dialog-title>Photo for <strong></strong> Device</h2>
    <hr>
    <mat-dialog-content>
        <div *ngIf=data>
            <div class="imgbox">
                <img [src]="data" alt=image class="center-fit">
            </div>
        </div>
    </mat-dialog-content>
    <hr>
    <mat-dialog-actions>
      <button btn btn-primary (click)="onCloseConfirm()"> <i class="fa fa-window-close"></i> Close</button>
      <button mat-raised-button (click)="onDownloadClick()"><i class="fa fa-download"></i> Download</button>
    </mat-dialog-actions>
</div>