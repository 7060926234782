// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewChild, AfterViewInit, Input, Inject, ElementRef   } from '@angular/core';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';  
import {MatPaginator} from '@angular/material/paginator';
// import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ServerService } from '../server.service';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap, tap} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { catchError, map } from 'rxjs/operators';  
import { UploadService } from  '../upload/upload.service';
import { summaryFileName } from '@angular/compiler/src/aot/util';
import {ViewDialogComponent} from '../viewer-dialog/viewer-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
// import {MatDialog} from '@angular/material/dialog';


export interface Device {
  id: string;
  storeid: string;
  address: string;
  filename: string;
  date: string,
}

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class DevicesComponent implements OnInit, AfterViewInit {

  columnsToDisplay: string[] = ['id','storeid', 'address', 'filename','date'];

  deviceCount: number = 0;
  filter: string = "";
  imageToShow: any;
  uploadInProgress: boolean = false;
  uploadProgressValue: number = 0;

  dataSource: DevicesDataSource;
   
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;files  = [];  

  constructor(private server: ServerService, private uploadService: UploadService, public dialog: MatDialog, private _snackBar: MatSnackBar) {
    // this.getProjects();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnInit(): void {
    this.dataSource = new DevicesDataSource(this.server);
    this.loadDevicesPage()
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadDevicesPage())
            )
        .subscribe();
    console.log(this.sort.active);
  }

  applyFilter(device: Device) {
    console.log("test");

  }
  

  async uploadFile(file, id) {  
    try {
      const formData = new FormData();  
      console.log(file.data.name);
      // file.data.name = id;
      formData.append('file', file.data);
      // formData.append('device_name', id); 
      file.inProgress = true;  
      this.uploadInProgress = true;
      // this.openSnackBar("Ping Sucess!","Success");  
      await this.uploadService.upload(formData, id).pipe(  
        map(event => {  
          switch (event.type) {  
            case HttpEventType.UploadProgress:  
              file.progress = Math.round(event.loaded * 100 / event.total);
              this.uploadProgressValue =  Math.round(event.loaded * 100 / event.total);
              console.log(file.progress); 
              break;  
            case HttpEventType.Response:  
              return event;  
          }  
        }),  
        catchError((error: HttpErrorResponse) => {  
          file.inProgress = false;  
          this.uploadInProgress = false;
          this.uploadProgressValue =  0;
          return of(`${file.data.name} upload failed.`);  
        })).subscribe( async (event: any) => {  
          if (typeof (event) === 'object') { 
            try {
              this.uploadProgressValue =  75;
              console.log("pinging device with: " + file.data.name); 
              var me = await this.server.checkUploadTrigger(id, file.data.name);
              console.log(me); 
              // this.pingDevice(id, file.data.name );
              console.log(event.body);  
              this.uploadInProgress = false;
              this.uploadProgressValue =  0;
              this.openSnackBar("Upload Success!","Success");
            }
            catch (error) {
              this.openSnackBar("Upload Failed!","Error");
              this.uploadInProgress = false;
              this.uploadProgressValue =  0;
              console.log(error);
            }
          }  
        });  
    }
    catch(error) {
      this.openSnackBar("Upload Failed!","Error");
      this.uploadInProgress = false;
      this.uploadProgressValue =  0;
      console.log(error);
    }
  }

  private uploadFiles() {  
    this.fileUpload.nativeElement.value = '';  
    this.files.forEach(file => {  
      // this.uploadFile(file);  
    });  
  }

  async onPingDeviceClick(id) {
    try {
      var status = await this.server.pingDeviceTrigger(id);
      console.log(status);
      this.openSnackBar("Ping Sucess!","Success");
    } catch (error) {
      this.openSnackBar("Ping Failed!","Error");
      console.log(error);
    }
    
    // if(status) {
    //   this.openSnackBar("Ping Sucessful!","Error");
    // }
    
    
  } 


  onUploadClick(id) {  
    const fileUpload = this.fileUpload.nativeElement;fileUpload.onchange = async () => {  
      console.log(fileUpload.files.length);
    // for (let index = 0; index < fileUpload.files.length; index++)  
    // {  
        const file = fileUpload.files[0];
        // file.name = id + ".jpg"
        console.log(file.name); 
        // this.files.push({ data: file, inProgress: false, progress: 0});  
    // } 
        this.fileUpload.nativeElement.value = ''; 
        await this.uploadFile({ data: file, inProgress: false, progress: 0}, id); 
    }; 
    fileUpload.click();  
  }

  async onViewPhotoClick(id) {
    console.log(id);
    // await this.uploadService.download(id).subscribe(
    //   async res => {
    //    console.log(res);
    //    let base64Data = res;
    //    this.imageToShow ='data:image/jpeg;base64,' +  await base64Data.text();
    //   //  this.createImageFromBlob(base64Data);
    //  }
    // );
    await this.uploadService.download(id).subscribe(
      async res => {
      //  console.log(res);
       let base64Data = res;
      //  this.createImageFromBlob(base64Data.slice(0,base64Data.size,'image/jpeg'));
      await this.createImageFromBlob(res);
     }
    );
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
      // this.imageToShow = "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
      //  console.log(this.imageToShow);
      let dialogRef = this.dialog.open(ViewDialogComponent, {
        width: '1400px',
        data: this.imageToShow,
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog closed: ${result}`);
        // this.dialogResult = result;
      });
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }
  private async getPhoto(id) {
    
  }

  private async loadDevicesPage() {
    // console.log("Testing, should see two")
    console.log(this.sort.active);
    await this.dataSource.loadDevices(this.filter,this.sort.active,this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  async pingDevice(deviceId, filename) {
    // "CVC-BT-South"
    console.log("test");
  }

  loadDevice(id) {
    console.log(id);
  }

}

export class DevicesDataSource implements DataSource<Device> {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  private devicesSubject = new BehaviorSubject<Device[]>([]);
  private deviceSubject = new BehaviorSubject<Device>({id: '' ,storeid: '', address: '', filename: '',date: ''});
  public loading$ = this.loadingSubject.asObservable();

  constructor(private server: ServerService) { }

  connect(collectionViewer: CollectionViewer): Observable<Device[]> {
    return this.devicesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.loadingSubject.complete();
      this.devicesSubject.complete();
      this.deviceSubject.complete();
      this.countSubject.complete();
  }

  loadDevices(filter = "",sortColumn="id", sortDirection = "asc", pageIndex = 0, pageSize = 10) {
    const params = {
      filter: filter,
      sort: sortDirection,
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortCol: sortColumn
    };
    this.loadingSubject.next(true);
    this.server.getDevicesTrigger(params).then((response: Device[]) => {
      console.log('Response', response);
      this.devicesSubject.next(response);
      this.loadingSubject.next(false);
    });
}

}