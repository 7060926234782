import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from '../environments/environment';
'use strict';

// import Client from ('@azure/azure-iot-device');
// var Protocol = require('azure-iot-device-mqtt').Mqtt;

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  DOWNLOAD_URL: string = "https://spectromnodetest.azurewebsites.net/api/BlobRetrievePhotoView?"
    constructor(private http: HttpClient, public oktaAuth: OktaAuthService) {
    }

    private async request(method: string, url: string, data?: any) {
      const token = await this.oktaAuth.getAccessToken();
      console.log("data");
      console.log(data);
      // console.log("token" + token);
      const result = this.http.request(method, url, {
        body: data,
        responseType: 'json',
        observe: 'body',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          Authorization: `Bearer ${token}`
        }
      });

      return new Promise((resolve, reject) => {
        result.subscribe(resolve, reject);
      });
    }

    getDevicesTrigger(params) {
      return this.request('GET', `${environment.apiUrl}CosmosdBTriggerHttpTest?${params.sort}&${params.sortCol}&${params.pageSize}&${params.pageIndex}`);
    }

    checkUploadTrigger(deviceId, filename) {
      console.log(deviceId);
      console.log(JSON.stringify({deviceId: deviceId, filename: filename}));
      return this.request('POST', `${environment.apiUrl}HttpTrigger1`, JSON.stringify({deviceId: deviceId, filename: filename}));
    }

    download(filename) {
      // const token = this.getToken();
      return this.request('GET',`${this.DOWNLOAD_URL}filename=${filename}`);
    }

    pingDeviceTrigger(deviceId) {
      return this.request('POST', `${environment.apiUrl}PingDeviceTrigger`, JSON.stringify({deviceId: deviceId}));
    }


  }

  