// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // hostUrl: 'http://localhost:4200/',
  // redirectUri: 'http://localhost:4200/implicit/callback',
  // // apiUrl: 'https://spectromnodetest.azurewebsites.net/api/HttpTrigger1',
  // // serverUrl: 'http://localhost:7071/api/CosmosdBTriggerHttpTest',
  // // apiUrl: 'http://localhost:4200/api/CosmosdBTriggerHttpTest',
  // apiUrl: 'https://spectromnodetest.azurewebsites.net/api/',

  hostUrl: 'https://victorious-plant-01b8bee10.azurestaticapps.net/',
  redirectUri: 'https://victorious-plant-01b8bee10.azurestaticapps.net/implicit/callback',
  apiUrl: 'https://spectromnodetest.azurewebsites.net/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
